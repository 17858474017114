import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../components/common/Section";
import SectionHeading from "../components/common/SectionHeading";
import Layout from "../layouts";

const Policy = () => {
  const { t } = useTranslation();
  return (
    <Layout title="general.tos">
      <Section className="pt-20">
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-16 mt-10">
          <div className="w-full">
            <SectionHeading as="h2">Polityka prywatności</SectionHeading>
            <p className="my-4 text-left lg:text-justify text-text-primary dark:text-text-primary-dark leading-7">
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Niniejszy dokument stanowi realizacj&#281; polityki
                  informacyjnej Administratora wobec u&#380;ytkownik&oacute;w
                  serwisu internetowego w domenie connectedlife.pl/ (Serwis) we
                  wszelkich aspektach przetwarzania i ochrony danych osobowych.
                  W wyniku przegl&#261;dania Serwisu lub w zwi&#261;zku z
                  kontaktami z Administratorem mo&#380;e doj&#347;&#263; do
                  przetwarzania danych osobowych. Przyk&#322;adamy
                  du&#380;&#261; wag&#281; do ochrony, zbierania, przetwarzania
                  i wykorzystywania Pa&#324;stwa danych osobowych zgodnie z
                  obowi&#261;zuj&#261;cymi przepisami.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  1. Informacja dotycz&#261;ca Administratora i gromadzenia
                  danych osobowych. &#377;r&oacute;d&#322;o pochodzenia danych.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c11">
                  1.1. Administratorem w rozumieniu art. 4 pkt 7
                  Rozporz&#261;dzenia Parlamentu Europejskiego I Rady (UE)
                  2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony
                  os&oacute;b fizycznych w zwi&#261;zku z przetwarzaniem danych
                  osobowych i w sprawie swobodnego przep&#322;ywu takich danych
                  oraz uchylenia dyrektywy 95/46/WE (dalej Rozporz&#261;dzenie)
                  w odniesieniu do Pa&#324;stwa danych osobowych jest
                  ConnectedLife sp&oacute;&#322;ka z ograniczon&#261;
                  odpowiedzialno&#347;ci&#261; z siedzib&#261; w Warszawie, ul.
                  Rakowiecka 41 lok. 21, 02-521 Warszawa, wpisana do rejestru
                  przedsi&#281;biorc&oacute;w Krajowego Rejestru S&#261;dowego,
                  pod numerem KRS 0000790268, NIP 5213868469, REGON: 383591553,
                  kapita&#322; zak&#322;adowy 10 000,00 z&#322;, w
                  ca&#322;o&#347;ci wp&#322;acony, S&#261;d rejestrowy: dla
                  M.St. Warszawy, S
                </span>
                <span className="c11">&#261;</span>
                <span className="c11">d Gospodarczy, XII Wydzia</span>
                <span className="c11">&#322;</span>
                <span className="c11">&nbsp;Krajowego Rejestru S</span>
                <span className="c11">&#261;</span>
                <span className="c7">
                  dowego, telefon +4&zwnj;8 22 119 28 43, &nbsp;adres email
                  rodo@connectedlife.pl (dalej Administrator).
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  1.2. Administrator przetwarza podane przez Pa&#324;stwa w
                  Serwisie Pa&#324;stwa dane identyfikacyjne i kontaktowe, a
                  ponadto dane zawarte w plikach cookies oraz dane nawigacyjne
                  Serwisu.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Dane identyfikacyjne i kontaktowe. Administrator przetwarza
                  podane przez Pa&#324;stwa dane osobowe w celu niezb&#281;dnym
                  do prawid&#322;owego funkcjonowania Serwisu, kontaktu za
                  po&#347;rednictwem narz&#281;dzi Serwisu, w tym formularza
                  kontaktowego, pozosta&#322;ej komunikacji i kontaktu, w tym w
                  sprawach sk&#322;adania i przyjmowania ofert, przes&#322;ania
                  informacji handlowych, zawierania i realizacji umowy
                  zawieranych za po&#347;rednictwem Serwisu, tj.
                  &nbsp;um&oacute;w o us&#322;ugi &#347;wiadczone drog&#261;
                  elektroniczn&#261;, zawierania i realizacji innych
                  um&oacute;w, sprawozdawczo&#347;ci rachunkowej i finansowej,
                  dochodzenia roszcze&#324;, a tak&#380;e w celach
                  marketingowych oraz statystycznych. W tych celach
                  Administrator gromadzi Pa&#324;stwa dane osobowe takie jak:
                  imi&#281; i ewentualnie nazwisko, adres e-mail, numer telefonu
                  oraz ewentualnie adres, nazwa firmy, NIP, REGON i inne dane
                  podane w toku kontaktu, w szczeg&oacute;lno&#347;ci w
                  formularzu kontaktowym. W przypadku zawierania umowy, podanie
                  danych osobowych jest niezb&#281;dne do wykonania umowy. W
                  razie, gdy przetwarzanie danych jest fakultatywne, dane
                  b&#281;d&#261; przetwarzane na podstawie zgody, co b&#281;dzie
                  wynika&#263; z tre&#347;ci udzielonej zgody.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Administrator przetwarza podane przez Pa&#324;stwa dane
                  osobowe w formularzu kontaktowym w celu realizacji us&#322;ugi
                  &#347;wiadczonej drog&#261; elektroniczn&#261; &ndash;
                  formularz kontaktowy. W tym celu Administrator gromadzi
                  Pa&#324;stwa dane osobowe takie jak: imi&#281;, adres e-mail,
                  numer telefonu. Podanie adresu e-mail jest niezb&#281;dne do
                  udzielania odpowiedzi na zapytanie z&#322;o&#380;one poprzez
                  formularz kontaktowy drog&#261; elektroniczn&#261;, a w
                  przypadku Pa&#324;stwa pro&#347;by o kontakt telefoniczny,
                  niezb&#281;dne jest r&oacute;wnie&#380; podanie numeru
                  telefonu. Przetwarzanie odbywa si&#281; na podstawie art. 6
                  ust. 1 lit. b) Rozporz&#261;dzenia.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Cookies. Administrator wykorzystuje tak&#380;e informacje
                  zawarte w plikach cookies w celach analitycznych i
                  pozosta&#322;ych celach opisanych w Polityce cookies.
                  Dostarczaj&#261; danych o aktywno&#347;ci
                  u&#380;ytkownik&oacute;w w Serwisie. Korzystanie z
                  plik&oacute;w cookies odbywa si&#281; na podstawie
                  Pa&#324;stwa zgody. Mog&#261; Pa&#324;stwo w ka&#380;dej
                  chwili wy&#322;&#261;czy&#263; pliki cookies poprzez
                  zmian&#281; ustawie&#324; w swojej przegl&#261;darce. Dane
                  zawarte w plikach cookies przetwarzane s&#261; na zasadach
                  okre&#347;lonych Polityk&#261; cookies.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Dane nawigacyjne. Systemy informatyczne i procedury
                  oprogramowania stworzone w celu zarz&#261;dzania Serwisem
                  pozyskuj&#261;, podczas zwyk&#322;ej dzia&#322;alno&#347;ci,
                  niekt&oacute;re dane osobowe, kt&oacute;rych transmisja jest
                  domy&#347;lna podczas u&#380;ycia protoko&#322;&oacute;w
                  komunikacyjnych. Ta kategoria danych obejmuje adresy IP lub
                  nazwy domen komputer&oacute;w i terminali u&#380;ywanych przez
                  u&#380;ytkownik&oacute;w, adresy w URI / URL (Uniform Resource
                  Identifier / Locator) &#380;&#261;danych zasob&oacute;w, czas
                  &#380;&#261;dania, metod&#281; u&#380;yt&#261; przy
                  wysy&#322;aniu &#380;&#261;dania do serwera,
                  wielko&#347;&#263; uzyskanego pliku zwrotnego, kod liczbowy
                  wskazuj&#261;cy status odpowiedzi udzielonej przez serwer
                  (sukces, b&#322;&#261;d, itp.) oraz inne parametry
                  zwi&#261;zane z systemem operacyjnym i &#347;rodowiskiem IT
                  U&#380;ytkownika. Dane te s&#261; wykorzystywane
                  wy&#322;&#261;cznie w celu uzyskania anonimowych informacji
                  statystycznych na temat u&#380;ycia strony internetowej oraz w
                  celu sprawdzenia prawid&#322;owego funkcjonowania oferowanych
                  us&#322;ug.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Google Analytics. Serwis korzysta r&oacute;wnie&#380; &nbsp;z
                  us&#322;ugi Google Analytics, w celu analizy ruchu w sieci
                  internetowej, zbierania i analizy danych dotycz&#261;cych
                  zachowania os&oacute;b odwiedzaj&#261;cych Serwis. Analiza
                  ruchu w sieci wykorzystywana jest g&#322;&oacute;wnie do
                  optymalizacji strony internetowej. Zgodnie z okre&#347;lonymi
                  funkcjonalno&#347;ciami Goolge Analytics piki
                  &bdquo;cookies&rdquo; mog&#261; by&#263; wykorzystywane w
                  celach marketingowych, a dane w nich zawarte podlega&#263;
                  profilowaniu.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Operatorem us&#322;ugi Google Analytics jest
                  sp&oacute;&#322;ka Google Inc., 1600 Amphitheatre Pkwy,
                  Mountain View, CA 94043-1351 z siedzib&#261; w USA.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  U&#380;ytkownik mo&#380;e nie wyrazi&#263; zgody na
                  gromadzenie danych zwi&#261;zanych z korzystaniem z Serwisu
                  przez Google Analytics, oraz na przetwarzanie tych danych
                  przez firm&#281; Google, a tak&#380;e mo&#380;e
                  uniemo&#380;liwi&#263; takie dzia&#322;ania. W tym celu
                  nale&#380;y pobra&#263; dodatek do przegl&#261;darki
                  dost&#281;pny tutaj: tools.google.com/dlpage/gaoptout, a
                  nast&#281;pnie zainstalowa&#263; go.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  1.3. Przetwarzanie odbywa si&#281; na podstawie:
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  a) art. 6 ust. 1 lit. b) Rozporz&#261;dzenia- w zakresie
                  danych osobowych niezb&#281;dnych do wykonania umowy, a
                  tak&#380;e w zakresie danych osobowych podawanych przez
                  Pa&#324;stwa w celu podj&#281;cia na Pa&#324;stwa
                  &#380;&#261;danie dzia&#322;a&#324; przed zawarciem umowy, np.
                  dla celu kontaktu przed zawarciem umowy, odpowiedzi na
                  pytania, komunikacji, w tym za po&#347;rednictwem formularza
                  kontaktowego, komunikatora itp.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  b) art. 6 ust. 1 lit. c) Rozporz&#261;dzenia- w zakresie
                  danych osobowych, kt&oacute;rych przetwarzanie jest
                  niezb&#281;dne do wype&#322;nienia obowi&#261;zku prawnego
                  ci&#261;&#380;&#261;cego na administratorze, np. w celu
                  wystawiania faktur, jak r&oacute;wnie&#380; dla cel&oacute;w
                  sprawozdawczo&#347;ci rachunkowej i finansowej
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  c) art. 6 ust. 1 lit f) Rozporz&#261;dzenia- w zakresie danych
                  osobowych, kt&oacute;rych przetwarzanie jest niezb&#281;dne do
                  cel&oacute;w wynikaj&#261;cych z prawnie uzasadnionych
                  interes&oacute;w realizowanych przez Administratora, tj.
                  przes&#322;ania informacji handlowych, jak r&oacute;wnie&#380;
                  w celu marketingu bezpo&#347;redniego lub dochodzenia
                  roszcze&#324;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  d) art. 6 ust. 1 lit a) Rozporz&#261;dzenia- w razie
                  wyra&#380;enia zgody, w przypadku, gdy przetwarzanie danych
                  jest fakultatywne.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  1.4. W odniesieniu do Pa&#324;stwa danych osobowych,
                  Administrator nie podejmuje zautomatyzowanych decyzji, decyzji
                  b&#281;d&#261;cych wynikiem zautomatyzowanego przetwarzania, w
                  tym profilowania w rozumieniu Rozporz&#261;dzenia. Dane
                  mog&#261; by&#263; jednak profilowane zgodnie z
                  funkcjonalno&#347;ciami Google Analytics.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  1.5. Administrator przechowuje Pa&#324;stwa dane osobowe
                  jedynie przez okres niezb&#281;dny do realizacji umowy, w tym
                  dochodzenia roszcze&#324; oraz zachowania zgodno&#347;ci z
                  wymaganiami wynikaj&#261;cymi z obowi&#261;zuj&#261;cych
                  przepis&oacute;w, w tym przepis&oacute;w podatkowych, jak
                  r&oacute;wnie&#380; przez czas niezb&#281;dny dla
                  pozosta&#322;ych cel&oacute;w przetwarzania wskazanych w
                  niniejszej Polityce Prywatno&#347;ci. W przypadku danych
                  osobowych przetwarzanych na podstawie Pa&#324;stwa zgody,
                  Administrator przechowuje podane dane osobowe przez okres
                  niezb&#281;dny do celu przetwarzania lub do wycofania zgody.
                  Po up&#322;ywie tych okres&oacute;w Pa&#324;stwa dane osobowe
                  zostan&#261; usuni&#281;te. Dane nawigacyjne nie s&#261;
                  przetwarzane przez okres d&#322;u&#380;szy ni&#380; siedem dni
                  (z wyj&#261;tkiem konieczno&#347;ci wynikaj&#261;cej z
                  dzia&#322;a&#324; w&#322;a&#347;ciwych organ&oacute;w, kiedy
                  mog&#261; by&#263; przetwarzane przez d&#322;u&#380;szy okres
                  zgodnie z t&#261; konieczno&#347;ci&#261;).
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2. Prawa osoby, kt&oacute;rej dane dotycz&#261;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.1. Przys&#322;uguje Pa&#324;stwu prawo do uzyskania od
                  Administratora potwierdzenia, czy przetwarza on Pa&#324;stwa
                  dane osobowe, prawo &#380;&#261;dania dost&#281;pu do tych
                  danych oraz prawo uzyskania od Administratora informacji
                  dotycz&#261;cych cel&oacute;w przetwarzania i kategorii
                  przetwarzanych danych osobowych, informacji o odbiorcach lub
                  kategoriach odbiorc&oacute;w, kt&oacute;rym dane osobowe
                  s&#261; ujawniane, planowanym okresie przechowywania danych
                  osobowych, &#378;r&oacute;dle danych w przypadku, gdy
                  zosta&#322;y zebrane nie od osoby, kt&oacute;rej dotycz&#261;
                  oraz informacji, czy Administrator podejmuje wobec podmiotu
                  danych zautomatyzowane decyzje, w tym m.in. w oparciu o
                  profilowanie. Maj&#261; Pa&#324;stwo tak&#380;e prawo do
                  uzyskania kopii danych.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.2. Ponadto przys&#322;uguje Pa&#324;stwu prawo
                  &#380;&#261;dania sprostowania danych osobowych, prawo
                  &#380;&#261;dania usuni&#281;cia danych osobowych, prawo
                  &#380;&#261;dania ograniczenia przetwarzania, prawo do
                  przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec
                  przetwarzania. Z uprawnie&#324; tych mog&#261; Pa&#324;stwo
                  skorzysta&#263;:
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.2.1. w odniesieniu do &#380;&#261;dania sprostowania danych:
                  gdy Pa&#324;stwa dane s&#261; nieprawid&#322;owe lub
                  niekompletne;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.2.2. w odniesieniu do &#380;&#261;dania usuni&#281;cia
                  danych: gdy Pa&#324;stwa dane nie b&#281;d&#261; ju&#380;
                  niezb&#281;dne do cel&oacute;w, dla kt&oacute;rych
                  zosta&#322;y zebrane przez Administratora; cofn&#261;
                  Pa&#324;stwo swoj&#261; zgod&#281; na przetwarzanie danych;
                  zg&#322;osz&#261; Pa&#324;stwo sprzeciw wobec przetwarzania
                  swoich danych; Pa&#324;stwa dane b&#281;d&#261; przetwarzane
                  niezgodnie z prawem; dane powinny by&#263; usuni&#281;te w
                  celu wywi&#261;zania si&#281; z obowi&#261;zku
                  wynikaj&#261;cego z przepisu prawa lub dane zosta&#322;y
                  zebrane w zwi&#261;zku z oferowaniem us&#322;ug
                  spo&#322;ecze&#324;stwa informacyjnego;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.2.3. w odniesieniu do &#380;&#261;dania ograniczenia
                  przetwarzania danych: gdy Pa&#324;stwa dane s&#261;
                  nieprawid&#322;owe &ndash; mog&#261; Pa&#324;stwo
                  &#380;&#261;da&#263; ograniczenia ich przetwarzania na okres
                  pozwalaj&#261;cy Administratorowi sprawdzi&#263;
                  prawid&#322;owo&#347;&#263; tych danych; przetwarzanie
                  Pa&#324;stwa danych odbywa si&#281; niezgodnie z prawem, ale
                  nie chc&#261; Pa&#324;stwo, aby zosta&#322;y usuni&#281;te;
                  Pa&#324;stwa dane nie b&#281;d&#261; ju&#380; potrzebne
                  Administratorowi, ale b&#281;d&#261; potrzebne Pa&#324;stwu do
                  ustalenia, dochodzenia lub obrony roszcze&#324;; lub
                  wnie&#347;li Pa&#324;stwo sprzeciw wobec przetwarzania danych
                  &ndash; do czasu ustalenia, czy prawnie uzasadnione podstawy
                  po stronie Administratora s&#261; nadrz&#281;dne wobec
                  podstawy sprzeciwu;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.2.4. w odniesieniu do &#380;&#261;dania przeniesienia
                  danych: gdy przetwarzanie Pa&#324;stwa danych odbywa si&#281;
                  na podstawie udzielonej zgody lub umowy oraz, gdy
                  przetwarzanie to odbywa si&#281; w spos&oacute;b
                  zautomatyzowany;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.2.5. w odniesieniu do prawa wniesienia sprzeciwu: gdy
                  przetwarzanie Pa&#324;stwa danych osobowych odbywa si&#281; na
                  podstawie prawnie uzasadnionego interesu, a sprzeciw jest
                  uzasadniony ze wzgl&#281;du na Pa&#324;stwa
                  szczeg&oacute;ln&#261; sytuacj&#281;, a tak&#380;e gdy
                  Pa&#324;stwa dane osobowe przetwarzane s&#261; na potrzeby
                  marketingu bezpo&#347;redniego, w tym s&#261; profilowane.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.3. Przys&#322;uguje r&oacute;wnie&#380; Pa&#324;stwu prawo
                  wniesienia skargi do organu nadzorczego, w przypadku uznania,
                  &#380;e dotycz&#261;ce Pa&#324;stwa przetwarzanie danych
                  osobowych narusza przepisy Rozporz&#261;dzenia. W Polsce
                  organem nadzorczym jest Prezes Urz&#281;du Ochrony Danych
                  Osobowych (ul. Stawki 2, 00-193 Warszawa).
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  2.4. Wdro&#380;one procedury bezpiecze&#324;stwa
                  oznaczaj&#261;, &#380;e przed wykonaniem
                  przys&#322;uguj&#261;cych Pa&#324;stwu uprawnie&#324;
                  mo&#380;emy zwr&oacute;ci&#263; si&#281; o potwierdzenie
                  to&#380;samo&#347;ci.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  3. Zgoda na przetwarzanie danych osobowych
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  3.1. W przypadku, gdy przetwarzanie danych jest fakultatywne,
                  np. je&#380;eli Administrator przetwarza dane osobowe,
                  kt&oacute;re nie s&#261; niezb&#281;dne do realizacji
                  us&#322;ugi lub umowy, podanie przez Pa&#324;stwa tych danych
                  nast&#281;puje zawsze dobrowolnie, po uprzednim wyra&#380;eniu
                  przez Pa&#324;stwa zgody na przetwarzanie podanych danych.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  3.2. Wyra&#380;enie zgody nast&#281;puje po zapoznaniu
                  si&#281; z niniejsz&#261; Polityk&#261; Prywatno&#347;ci. W
                  takim przypadku wyra&#380;aj&#261; Pa&#324;stwo zgod&#281; na
                  gromadzenie i przetwarzanie przez Administratora podanych
                  przez siebie danych osobowych w celu wyra&#378;nie wskazanym
                  przy wyra&#380;aniu zgody, w spos&oacute;b okre&#347;lony w
                  ramach zgody, np. przez zaznaczenie odpowiedniego okienka.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  3.3. Mog&#261; Pa&#324;stwo w dowolnym momencie wycofa&#263;
                  swoj&#261; zgod&#281;, w taki sam spos&oacute;b, w jaki zgoda
                  zosta&#322;a udzielona.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  3.4. Ponadto mog&#261; Pa&#324;stwo zawsze wycofa&#263;
                  zgod&#281; wysy&#322;aj&#261;c do Administratora
                  o&#347;wiadczenie o wycofaniu zgody w spos&oacute;b wskazany w
                  punkcie 6 niniejszej Polityki Prywatno&#347;ci.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  3.5. Wycofanie zgody nie wp&#322;ywa na zgodno&#347;&#263; z
                  prawem przetwarzania, kt&oacute;rego dokonano na podstawie
                  zgody przed jej wycofaniem.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4. Informacja o odbiorcach / kategoriach odbiorc&oacute;w
                  danych osobowych
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.1. Administrator mo&#380;e korzysta&#263;
                  r&oacute;wnie&#380; cz&#281;&#347;ciowo z zewn&#281;trznych
                  dostawc&oacute;w us&#322;ug, kt&oacute;rzy w imieniu
                  Administratora przetwarzaj&#261; dane osobowe, np.
                  dostawc&oacute;w us&#322;ug hostingu, dostawc&oacute;w
                  us&#322;ug poczty elektronicznej, dostawc&oacute;w us&#322;ug
                  ksi&#281;gowych, dostawc&oacute;w us&#322;ug p&#322;atniczych,
                  dostawc&oacute;w us&#322;ug wysy&#322;kowych, dostawc&oacute;w
                  us&#322;ug windykacyjnych, dostawc&oacute;w us&#322;ug
                  marketingowych. Przekazanie danych mo&#380;e jednak
                  s&#322;u&#380;y&#263; wy&#322;&#261;cznie realizacji ich
                  us&#322;ugi. Administrator korzysta wy&#322;&#261;cznie z
                  us&#322;ug takich podmiot&oacute;w, kt&oacute;rzy
                  zapewniaj&#261; wystarczaj&#261;ce gwarancje ochrony praw
                  os&oacute;b, kt&oacute;rych dane dotycz&#261;. Je&#347;li dane
                  podmioty nie s&#261; samodzielnymi administratorami,
                  przetwarzanie danych osobowych przez te podmioty odbywa
                  si&#281; na podstawie pisemnych um&oacute;w zawartych z
                  Administratorem. Podmioty te przestrzegaj&#261; wytycznych
                  Administratora i podlegaj&#261; prowadzonym przez niego
                  audytom. Pa&#324;stwa dane, udost&#281;pnione przez
                  Pa&#324;stwa w Serwisie, w szczeg&oacute;lno&#347;ci
                  wizerunek, b&#281;d&#261; dost&#281;pne do zapoznania si&#281;
                  tak&#380;e wobec u&#380;ytkownik&oacute;w Serwisu.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.2. Pa&#324;stwa mog&#261; przekazane podmiotom
                  powi&#261;zanym z Administratorem w celach &#347;ci&#347;le
                  zwi&#261;zanych i niezb&#281;dnych dla &#347;wiadczenia
                  us&#322;ug, takich jak zarz&#261;dzanie systemami
                  informatycznymi lub w celu przetwarzania operacji wykonanych
                  przez inne oddzia&#322;y grupy w tych samych celach.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.3. Dane osobowe dostarczone przez u&#380;ytkownika
                  zamawiaj&#261;cego wysy&#322;k&#281; materia&#322;&oacute;w
                  informacyjnych (broszury i inne materia&#322;y informacyjne,
                  itp.) b&#281;d&#261; u&#380;yte wy&#322;&#261;cznie w celu
                  zorganizowania dostawy i ujawnione osobom trzecim (firmy
                  pocztowe, znakuj&#261;ce, kurierskie, itp.) jedynie,
                  je&#347;li jest to konieczne do jego osi&#261;gni&#281;cia.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.4. Ponadto, w zwi&#261;zku z korzystaniem przez
                  Administratora z okre&#347;lonych us&#322;ug &nbsp;w tym np.
                  gmail, google analytics, google drive, G-suite, dane mog&#261;
                  by&#263; przekazywane poza obszar EOG, ale tylko pod warunkiem
                  istnienia gwarancji zapewnienia odpowiedniego stopnia ochrony,
                  np. wynikaj&#261;cych z uczestnictwa podmiotu w programie
                  Tarcza Prywatno&#347;ci (Privacy Shield), ustanowionym na mocy
                  decyzji wykonawczej Komisji (UE) 2016/1250 z dnia 12 lipca
                  2016 r. w sprawie adekwatno&#347;ci ochrony zapewnianej przez
                  Tarcz&#281; Prywatno&#347;ci UE-USA.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.5. Administrator posiada r&oacute;wnie&#380; stron&#281;
                  typu fanpage w serwisie internetowym Facebook i Instagram. W
                  zwi&#261;zku z tym dane w postaci imienia i nazwiska lub inne
                  dane odpowiednio do funkcjonalno&#347;ci tych serwis&oacute;w
                  mog&#261; by&#263; gromadzone, a nast&#281;pnie
                  udost&#281;pnianie operatorowi serwisu Facebook lub Instagram,
                  a w takim przypadku przetwarzanie danych dokonywane jest w
                  celu korzystania ze strony administratora w tych serwisach i
                  marketingu z pomoc&#261; tych serwis&oacute;w.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.6. Serwis zawiera linki do innych stron. Ich
                  obecno&#347;&#263; mo&#380;e oznacza&#263; zbieranie danych
                  przez strony i dla stron zarz&#261;dzanych przez osoby
                  trzecie. Zarz&#261;dzanie informacjami zebranymi przez
                  &bdquo;osoby trzecie&rdquo; r&oacute;wnie&#380; podlega
                  odpowiedniej regulacji zgodnie z zasadami stosowanymi przez
                  danego odr&#281;bnego administratora.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.7. Swoje informacje powierzaj&#261; Pa&#324;stwo
                  r&oacute;wnie&#380; naszym zaufanym partnerom, czyli stronom
                  trzecim z kt&oacute;rymi stale wsp&oacute;&#322;pracujemy.
                  Najcz&#281;&#347;ciej ta wsp&oacute;&#322;praca ma na celu
                  dostosowywanie do potrzeb i zainteresowa&#324; reklam,
                  kt&oacute;re s&#261; widoczne na naszych stronach i
                  us&#322;ug, kt&oacute;re dostarczamy.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Aby m&oacute;c prezentowa&#263; reklamy (np. produktu)
                  udoskonala&#263; nasze us&#322;ugi i maksymalnie
                  dopasowa&#263; je do Pa&#324;stwa zainteresowa&#324; nasi
                  zaufani partnerzy maj&#261; mo&#380;liwo&#347;&#263;
                  przetwarzania Pa&#324;stwa danych zwi&#261;zanych z
                  odwiedzanymi stronami internetowymi (wraz ze
                  zautomatyzowan&#261; analiz&#261; aktywno&#347;ci na stronach
                  internetowych i w aplikacjach w celu ustalenia potencjalnych
                  zainteresowa&#324; dla dostosowania reklamy). Podstaw&#261;
                  prawn&#261; przetwarzania Pa&#324;stwa danych w celu
                  &#347;wiadczenia us&#322;ug, w tym dopasowywania ich do
                  Pa&#324;stwa zainteresowa&#324;, analizowania ich i
                  udoskonalania oraz zapewniania ich bezpiecze&#324;stwa jest
                  niezb&#281;dno&#347;&#263; do wykonania um&oacute;w o ich
                  &#347;wiadczenie (tymi umowami s&#261; zazwyczaj regulaminy).
                  Podstaw&#261; prawn&#261; przetwarzania danych w celu
                  pomiar&oacute;w statystycznych i marketingu w&#322;asnego
                  administrator&oacute;w jest tzw. uzasadniony interes
                  administratora.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Nasi zaufani partnerzy przetwarzaj&#261; Pa&#324;stwa dane
                  osobowe w celach marketingowych na podstawie Pa&#324;stwa
                  zgody. Zgoda jest dobrowolna i mo&#380;na j&#261; w dowolnym
                  momencie wycofa&#263;.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  Na naszych stronach podmioty trzecie zamieszczaj&#261;
                  informacje w formie plik&oacute;w cookies (tzw. ciasteczek) i
                  innych podobnych technologii w urz&#261;dzeniu ko&#324;cowym
                  (np. komputerze, smartfonie) oraz uzyskuj&#261; do nich
                  dost&#281;p. To nasi zaufani partnerzy z kt&oacute;rymi stale
                  wsp&oacute;&#322;pracujemy aby dostosowa&#263; do
                  &nbsp;potrzeb i zainteresowa&#324; reklamy, kt&oacute;re
                  widoczne s&#261; na naszych stronach i us&#322;ugi,
                  kt&oacute;re dostarczamy.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  4.8. Poza wy&#380;ej wymienionymi okoliczno&#347;ciami, dane
                  nie zostan&#261; udost&#281;pnione nikomu, za wyj&#261;tkiem
                  sytuacji podyktowanych wymogami umowy lub za zgod&#261;
                  zainteresowanego. W tych przypadkach, dane osobowe mog&#261;
                  by&#263; udost&#281;pnione osobom trzecim, wy&#322;&#261;cznie
                  pod warunkiem, &#380;e:
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  &ndash; wyra&#380;ono wyra&#378;n&#261; zgod&#281; na
                  udost&#281;pnienie danych osobom trzecim;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  &ndash; istnieje potrzeba udost&#281;pnienia danych osobom
                  trzecim w celu wykonaniu zam&oacute;wionej us&#322;ugi lub
                  wykonania umowy;
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  &ndash; &nbsp;na &#380;&#261;danie uprawnionych
                  organ&oacute;w.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  W &#380;adnych innych przypadkach dane nie b&#281;d&#261;
                  rozpowszechniane.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  5. Bezpiecze&#324;stwo danych osobowych
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">
                  5.1. Administrator przetwarza Pa&#324;stwa dane osobowe
                  zgodnie z przepisami Rozporz&#261;dzenia, w tym stosuje
                  odpowiednie &#347;rodki techniczne i organizacyjne w celu
                  zapewnienia bezpiecze&#324;stwa i odpowiedniej poufno&#347;ci
                  i integralno&#347;ci danych osobowych, w tym ochrony przed
                  nieuprawnionym dost&#281;pem, nieautoryzowan&#261;
                  modyfikacj&#261;, ujawnieniem lub zniszczeniem tych danych.
                </span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c7">6. Dane kontaktowe</span>
              </p>
              <p className="pt-0 pb-4 leading-6 text-left">
                <span className="c11">
                  6.1. Wszelkie &#380;&#261;dania, pro&#347;by, powiadomienia,
                  zapytania odnosz&#261;ce si&#281; do przetwarzania danych
                  osobowych, mog&#261; Pa&#324;stwo kierowa&#263; mailowo na
                  adres: rodo@connectedlife.pl lub pisemnie na adres:
                  ConnectedLife sp&oacute;&#322;ka z ograniczon&#261;
                  odpowiedzialno&#347;ci&#261; z siedzib&#261; w Warszawie, ul.
                  Rakowiecka 41 lok. 21, 02-521 Warszawa. W ten sam
                  spos&oacute;b mog&#261; si&#281; Pa&#324;stwo
                  skontaktowa&#263; z naszym Inspektorem Ochrony Danych
                  Osobowych: adw.{" "}
                </span>
                <span className="c34">
                  <a className="c23" href="mailto:m.chudziak@chw-adwokaci.com">
                    Mateusz Chudziak
                  </a>
                </span>
              </p>
              <p className="c16">
                <span className="c7">Stan na dzie&#324; 01.03.2021</span>
              </p>
            </p>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default Policy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
